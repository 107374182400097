import React from "react"

import InnerLayout from "../components/InnerLayout"
import InnerWrapper from "../components/InnerWrapper"
import SEO from "../components/seo"

import icons from '../images/icons.svg';
const PointerIcon = icons + '#pointer';

const data = [
  'Currently booked with client work for Q1 2021. Mainly with a Norwegian agency, working on a large project for a Nordic energy company',
  'Currently reading "Pocket Full Of Do" by Chris Do',
  'Trying to improve my skills within Next.js and TypeScript',
  'Still creating and exploring some cool experiments with <a href="https://datasapiens.life/" target="_blank" rel="noopener noreferrer">Data Sapiens</a>',
  'Still building and growing the <a href="https://careermentor.dk/" target="_blank" rel="noopener noreferrer">CareerMentor</a> web application. A Danish online platform for matching mentor and mentees within their professional life',
]

const NowPage = () => {
  return (
    <InnerLayout>
      <SEO title="What I am up to right now"/>
      <InnerWrapper>
        <section className="outer-padding-x outer-padding-y">
          <h1 className="fadein">/Now</h1>
          <div className="max-width Now fadein" style={{transitionDelay: '0.1s'}}>
            <p className="Now__updated">[ Last updated: January 1st 2021 ]</p>
            <ul className="list-unstyled Now__list">
              {data.map((text, index) =>
                <li key={index}>
                  <svg className="fill-black">
                    <use xlinkHref={PointerIcon}></use>
                  </svg>
                  <span dangerouslySetInnerHTML={{ __html: text }}></span>
                </li>
              )}
            </ul>
          </div>
        </section>
      </InnerWrapper>
    </InnerLayout>
  )
}

export default NowPage
